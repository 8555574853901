import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TextField,TableCell, TableContainer, TableHead, TableRow, Paper, Typography, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import baseUrl from '../../config';

const PreTeamList = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [performanceData, setPerformanceData] = useState(null);
  const [memberId, setMemberId] = useState('');
  const [unassignedMembers, setUnassignedMembers] = useState([]);
  const [addingMember, setAddingMember] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  useEffect(() => {
    // Fetching teams and members
    const getTeamsAndMembers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(baseUrl + 'api/manager/preteams', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        setTeams(response.data.teams);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching teams:', error);
        setLoading(false);
      }
    };

    getTeamsAndMembers();
  }, []);

  // Fetch Team Performance
  const handleCheckPerformance = async (teamId) => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}api/manager/team-performance/${teamId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      setPerformanceData(response.data.teamPerformance);
      setLoading(false);
    } catch (error) {
      setSnackbarMessage('Error fetching team performance');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      setLoading(false);
    }
  };

  // Open Add Member dialog for a specific team
  const handleOpenDialog = (team) => {
    setSelectedTeam(team);
    setOpenDialog(true);
    setMemberId('');
    setUnassignedMembers(
      teams
        .filter((t) => t._id !== team._id)
        .flatMap((t) => t.members)
        .map((m) => m._id)
    );
  };

  // Close Add Member dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTeam(null);
    setMemberId('');
  };

  // Handle adding a member to the selected team
  const handleAddMember = async () => {
    if (!memberId) {
      alert('Please enter a valid member ID.');
      return;
    }

    setAddingMember(true);
    try {
      const response = await axios.post(
        `${baseUrl}api/manager/teams/${selectedTeam._id}/members`,
        { memberId },
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      const updatedTeam = response.data.team;

      // Update the team list with the newly added member
      setTeams((prevTeams) =>
        prevTeams.map((team) => (team._id === updatedTeam._id ? updatedTeam : team))
      );

      handleCloseDialog();
    } catch (error) {
      console.error('Error adding member to team:', error);
      alert('Error adding member');
    } finally {
      setAddingMember(false);
    }
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Presales Teams
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team Name</TableCell>
                <TableCell>Manager</TableCell>
                <TableCell>Members</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((team) => (
                <TableRow key={team._id}>
                  <TableCell>{team.teamName}</TableCell>
                  <TableCell>{team.manager.name}</TableCell>
                  <TableCell>
                    {team.members.map((member) => member.name).join(', ')}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleOpenDialog(team)}
                    >
                      Add Member
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleCheckPerformance(team._id)}
                      sx={{ marginLeft: 2 }}
                    >
                      Check Performance
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Add Member Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Add Member to {selectedTeam?.teamName}</DialogTitle>
        <DialogContent>
          <TextField
            label="Member ID"
            value={memberId}
            onChange={(e) => setMemberId(e.target.value)}
            fullWidth
          />
          {unassignedMembers.length > 0 ? (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Member Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unassignedMembers.map((member) => (
                    <TableRow key={member._id}>
                      <TableCell>{member.name}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleAddMember(member._id)}
                          disabled={addingMember}
                        >
                          {addingMember ? 'Adding...' : 'Add to Team'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography variant="body1" color="textSecondary">
              No members found
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Team Performance Dialog */}
      {performanceData && (
        <Dialog open={Boolean(performanceData)} onClose={() => setPerformanceData(null)}>
          <DialogTitle>Team Performance for {performanceData.teamName}</DialogTitle>
          <DialogContent>
            <Typography variant="h6">Manager: {performanceData.manager}</Typography>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Member Name</TableCell>
                    <TableCell>Assigned Leads</TableCell>
                    <TableCell>Transferred Leads</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {performanceData.members.map((member) => (
                    <TableRow key={member.memberName}>
                      <TableCell>{member.memberName}</TableCell>
                      <TableCell>{member.assignedLeads}</TableCell>
                      <TableCell>{member.transferredLeads}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              Total Assigned Leads: {performanceData.totalAssignedLeads}
            </Typography>
            <Typography variant="h6">
              Total Transferred Leads: {performanceData.totalTransferredLeads}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setPerformanceData(null)} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PreTeamList;
