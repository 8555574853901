// src/components/LeadListPage.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Axios for API calls

const LeadListPage = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [teamPerformance, setTeamPerformance] = useState([]);
  const [selectedMemberPerformance, setSelectedMemberPerformance] = useState(null);

  // Fetch all leads
  useEffect(() => {
    axios.get('/api/leads')
      .then((response) => {
        setLeads(response.data.leads);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching leads:', error);
        setLoading(false);
      });
  }, []);

  // Fetch team performance
  useEffect(() => {
    axios.get('/api/team-performance')
      .then((response) => {
        setTeamPerformance(response.data.teamPerformance);
      })
      .catch((error) => {
        console.error('Error fetching team performance:', error);
      });
  }, []);

  // Fetch individual team member performance
  const fetchMemberPerformance = (memberId) => {
    axios.get(`/api/team-member-performance/${memberId}`)
      .then((response) => {
        setSelectedMemberPerformance(response.data.memberPerformance);
      })
      .catch((error) => {
        console.error('Error fetching member performance:', error);
      });
  };

  // Update lead status to 'Operations'
  const sendToOperations = (leadId) => {
    axios.patch(`/api/leads/${leadId}/status`, { status: 'In Progress', assignedTo: 'Operations Team' })
      .then((response) => {
        const updatedLead = response.data.lead;
        setLeads((prevLeads) =>
          prevLeads.map((lead) => (lead._id === leadId ? updatedLead : lead))
        );
      })
      .catch((error) => {
        console.error('Error sending lead to Operations:', error);
      });
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 3 }}>
        Lead List
      </Typography>

      {/* Lead Table */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card elevation={3} sx={{ padding: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                Leads Overview
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Name</strong></TableCell>
                      <TableCell><strong>Email</strong></TableCell>
                      <TableCell><strong>Status</strong></TableCell>
                      <TableCell><strong>Assigned To</strong></TableCell>
                      <TableCell><strong>Actions</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leads.map((lead) => (
                      <TableRow key={lead._id}>
                        <TableCell>{lead.name}</TableCell>
                        <TableCell>{lead.email}</TableCell>
                        <TableCell>{lead.status}</TableCell>
                        <TableCell>{lead.assignedTo}</TableCell>
                        <TableCell>
                          <Button 
                            variant="contained" 
                            color="secondary"
                            size="small"
                            onClick={() => sendToOperations(lead._id)}  // Call sendToOperations when clicked
                          >
                            Send to Operations
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Performance Overview */}
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card elevation={3} sx={{ padding: 3 }}>
            <CardContent>
              <Typography variant="h6" sx={{ marginBottom: 2, fontWeight: 'bold' }}>
                Team Performance
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Team Member</strong></TableCell>
                      <TableCell><strong>Total Leads</strong></TableCell>
                      <TableCell><strong>Leads In Progress</strong></TableCell>
                      <TableCell><strong>Leads Closed</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teamPerformance.map((team) => (
                      <TableRow key={team._id}>
                        <TableCell>{team._id}</TableCell>
                        <TableCell>{team.totalLeads}</TableCell>
                        <TableCell>{team.leadsInProgress}</TableCell>
                        <TableCell>{team.leadsClosed}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LeadListPage;
