import React, { useState, useEffect } from 'react';
import { Button, Grid, TextField, Typography, Box, Card, CardContent, MenuItem, Select, InputLabel, FormControl, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import baseUrl from '../../config';

// Styled components for the layout
const CardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: '#fafafa',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
}));

const PreSalesTeamManagement = () => {
    const [teamName, setTeamName] = useState('');
    const [members, setMembers] = useState([]); // Sales team members
    const [allMembers, setAllMembers] = useState([]); // All available presales members
    const [assignedMembers, setAssignedMembers] = useState([]); // Members already assigned to teams
    const [loading, setLoading] = useState(false); // Loading state for API requests
    const [error, setError] = useState(''); // Error message state

    // Fetch presales users from the backend
    useEffect(() => {
        const fetchPresalesUsers = async () => {
            setLoading(true);
            try {
                const response = await axios.get(baseUrl+'api/manager/presales', {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setAllMembers(response.data.presalesUsers); // Store presales users
            } catch (error) {
                setError('Failed to fetch presales users');
            } finally {
                setLoading(false);
            }
        };

        fetchPresalesUsers();
    }, []);

    const handleAddMember = (member) => {
        if (members.length < 5) {
            setMembers([...members, member]);
            setAssignedMembers([...assignedMembers, member._id]); // Mark the member as assigned
        } else {
            alert("A team can only have 5 members.");
        }
    };

    const handleRemoveMember = (index) => {
        const newMembers = [...members];
        const removedMember = newMembers.splice(index, 1);
        setMembers(newMembers);
        setAssignedMembers(assignedMembers.filter(id => id !== removedMember[0]._id)); // Unassign member
    };
console.log(localStorage.getItem('token'),"  token");

const handleCreateSalesTeam = async () => {
    if (members.length === 0) {
        alert("Please add at least one member to the team.");
    } else {
        try {
            setLoading(true);

            // Initialize memberIds here first
            const memberIds = members.map(member => member._id); // Get member IDs using _id
            
            // Log memberIds after it has been initialized
            console.log(memberIds, "memberIds");

            // Send request to create the sales team
            const response = await axios.post(
                baseUrl + 'api/manager/teams/presales', // URL
                { 
                    teamName, // Body data
                    memberIds 
                }, 
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}` // Authorization header
                    }
                }
            );

            // Log the response and alert success
            console.log(response);
            alert('Sales team created successfully!');
            console.log(response.data); // Log response for debugging
        } catch (error) {
            setError('Error creating sales team');
            console.error('Error creating sales team:', error);
        } finally {
            setLoading(false);
        }
    }
};

    // Filter available members to show only those with "presales" role and who are not already assigned to a team
    const availableMembers = allMembers.filter(member => 
        member.role === 'presales' && !assignedMembers.includes(member._id)
    );

    return (
        <>
            <Box padding={4}>
                <Typography variant="h4" gutterBottom>
                    Presales Team Management
                </Typography>

                <CardContainer>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Create PreSales Team
                        </Typography>
                        <TextField
                            fullWidth
                            label="PreSales Team Name"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            variant="outlined"
                            margin="normal"
                        />
                        <Typography variant="h6" gutterBottom>
                            Add Team Members
                        </Typography>

                        {/* List selected team members */}
                        {members.map((member, index) => (
                            <Grid container spacing={3} key={index}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Member Name"
                                        value={member.name}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Role"
                                        value={member.role}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleRemoveMember(index)}
                                        style={{ marginTop: '20px' }}
                                    >
                                        Remove Member
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}

                        {/* Available members dropdown */}
                        {members.length < 5 && (
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel>Select Member</InputLabel>
                                <Select
                                    value=""
                                    onChange={(e) => handleAddMember(availableMembers.find(m => m._id === e.target.value))}
                                    label="Select Member"
                                >
                                    {availableMembers.map((member) => (
                                        <MenuItem key={member._id} value={member._id}>
                                            {member.name} ({member.role})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {/* Action Buttons */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreateSalesTeam}
                            style={{ marginTop: '20px' }}
                            disabled={loading} // Disable button while loading
                        >
                            {loading ? <CircularProgress size={24} /> : 'Create PreSales Team'}
                        </Button>

                        {/* Error message */}
                        {error && (
                            <Typography variant="body2" color="error" style={{ marginTop: '20px' }}>
                                {error}
                            </Typography>
                        )}
                    </CardContent>
                </CardContainer>
            </Box>
        </>
    );
};

export default PreSalesTeamManagement;
