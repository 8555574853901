import React, { useState, useEffect } from 'react';
import { Typography, TextField, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Chip, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { usersDataByCountry } from '../test/usersData'; // Assuming this data is available
import { styled } from '@mui/system';
import DashboardLayout from './Sidebar';

// Styled Search Bar
const SearchBox = styled(TextField)(({ theme }) => ({
    margin: theme.spacing(3, 0),
    '& .MuiInputBase-root': {
        borderRadius: '20px',
        paddingLeft: '16px',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.primary.light,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.dark,
        },
    },
}));

const UserListPage = () => {
    const { countryName } = useParams(); // Get the country name from the URL
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [sortField, setSortField] = useState('name');

    // Get the users based on country
    useEffect(() => {
        const countryUsers = usersDataByCountry[countryName] || [];
        setUsers(countryUsers);
    }, [countryName]);

    // Filter users based on search input
    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.role.toLowerCase().includes(search.toLowerCase())
    );

    // Sorting function
    const handleRequestSort = (property) => {
        const isAsc = sortField === property && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortField(property);
    };

    // Sorting logic
    const sortedUsers = filteredUsers.sort((a, b) => {
        if (sortField === 'status') {
            return sortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
        }
        if (a[sortField] < b[sortField]) return sortDirection === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortDirection === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <DashboardLayout>
            <Box padding={4}>
                <Typography variant="h3" gutterBottom align="center" color="primary" fontWeight="bold">
                    Users from {countryName}
                </Typography>

                {/* Search Bar */}
                <SearchBox
                    label="Search Users"
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />

                {/* User Table */}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'name'}
                                        direction={sortField === 'name' ? sortDirection : 'asc'}
                                        onClick={() => handleRequestSort('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'email'}
                                        direction={sortField === 'email' ? sortDirection : 'asc'}
                                        onClick={() => handleRequestSort('email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'role'}
                                        direction={sortField === 'role' ? sortDirection : 'asc'}
                                        onClick={() => handleRequestSort('role')}
                                    >
                                        Role
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Phone</TableCell>
                                <TableCell>Timezone</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedUsers.map((user) => (
                                <TableRow hover key={user.id}>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>{user.phoneNumber}</TableCell>
                                    <TableCell>{user.timezone}</TableCell>
                                    <TableCell>
                                        <Chip
                                            label={user.status}
                                            color={user.status === 'Active' ? 'success' : 'error'}
                                            sx={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Button variant="contained" color="primary" size="small">
                                            View Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </DashboardLayout>
    );
};

export default UserListPage;
