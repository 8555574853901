// src/Page/sales/SalesDashboard.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, Divider, List, ListItem, ListItemText, Button } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

const SalesDashboard = () => {
  // Sample sales data
  const [salesData, setSalesData] = useState({
    totalSales: 50000,
    totalLeads: 150,
    closedDeals: 80,
    salesGoal: 100000,
    leadStatus: [
      { name: 'New Leads', value: 40 },
      { name: 'In Progress', value: 60 },
      { name: 'Closed Deals', value: 30 },
      { name: 'Lost Leads', value: 10 }
    ],
    salesTrend: [
      { month: 'Jan', sales: 10000 },
      { month: 'Feb', sales: 15000 },
      { month: 'Mar', sales: 18000 },
      { month: 'Apr', sales: 22000 },
    ],
    leadSource: [
      { name: 'Website', value: 50 },
      { name: 'Referral', value: 30 },
      { name: 'Social Media', value: 15 },
      { name: 'Paid Ads', value: 5 },
    ]
  });

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Sales Dashboard
      </Typography>

      {/* Sales Performance Summary */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: 2, backgroundColor: '#e3f2fd' }}>
            <Typography variant="h6">Total Sales</Typography>
            <Typography variant="h5">${salesData.totalSales}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: 2, backgroundColor: '#fce4ec' }}>
            <Typography variant="h6">Leads Created</Typography>
            <Typography variant="h5">{salesData.totalLeads}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: 2, backgroundColor: '#fff3e0' }}>
            <Typography variant="h6">Closed Deals</Typography>
            <Typography variant="h5">{salesData.closedDeals}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper sx={{ padding: 2, backgroundColor: '#c8e6c9' }}>
            <Typography variant="h6">Sales Goal</Typography>
            <Typography variant="h5">${salesData.salesGoal}</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ margin: 3 }} />

      {/* Lead Status Breakdown */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Lead Status</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={salesData.leadStatus}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#8884d8"
                >
                  {salesData.leadStatus.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={['#8e44ad', '#3498db', '#2ecc71', '#e74c3c'][index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Lead Source Breakdown */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h6">Lead Source Breakdown</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={salesData.leadSource}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  fill="#82ca9d"
                >
                  {salesData.leadSource.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={['#ff8c00', '#8b0000', '#ffd700', '#4682b4'][index]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ margin: 3 }} />

      {/* Sales Trend Over Time */}
      <Grid item xs={12}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6">Sales Trend (Last 4 Months)</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={salesData.salesTrend}>
              <Line type="monotone" dataKey="sales" stroke="#8884d8" />
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
            </LineChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
    </Box>
  );
};

export default SalesDashboard;
