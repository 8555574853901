import React, { useState, useEffect } from 'react';
import { Typography, Grid, Card, CardContent, TextField, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import DataTable from '../../Component/DataTable';
import DashboardLayout from '../../Component/Sidebar';

// Sample performance data for teams
const salesData = [
    { name: 'Jan', sales: 4000 },
    { name: 'Feb', sales: 3000 },
    { name: 'Mar', sales: 5000 },
    { name: 'Apr', sales: 2000 },
    { name: 'May', sales: 6000 },
    { name: 'Jun', sales: 3500 },
];

const operationsData = [
    { name: 'Jan', tasksCompleted: 50 },
    { name: 'Feb', tasksCompleted: 60 },
    { name: 'Mar', tasksCompleted: 40 },
    { name: 'Apr', tasksCompleted: 70 },
    { name: 'May', tasksCompleted: 80 },
    { name: 'Jun', tasksCompleted: 75 },
];

const presalesData = [
    { name: 'Jan', leads: 20 },
    { name: 'Feb', leads: 30 },
    { name: 'Mar', leads: 25 },
    { name: 'Apr', leads: 40 },
    { name: 'May', leads: 35 },
    { name: 'Jun', leads: 50 },
];

// Users data by country
const usersByCountryData = [
    { country: 'USA', users: 120, converted: 30 },
    { country: 'UK', users: 80, converted: 20 },
    { country: 'Germany', users: 60, converted: 15 },
    { country: 'Canada', users: 40, converted: 10 },
    { country: 'Australia', users: 30, converted: 5 },
    { country: 'India', users: 50, converted: 12 },
    { country: 'France', users: 70, converted: 18 },
    { country: 'Brazil', users: 90, converted: 25 },
];

const DashboardScreen = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [checkedIn, setCheckedIn] = useState(false); // Track check-in status
    const [loginCount, setLoginCount] = useState(0);  // Track login count
    const [checkInTime, setCheckInTime] = useState(null); // Store check-in time
    const [checkOutTime, setCheckOutTime] = useState(null); // Store check-out time

    // Handle check-in and check-out
    const handleCheckIn = () => {
        setCheckedIn(true);
        const currentTime = new Date().toLocaleString();
        setCheckInTime(currentTime);
        setLoginCount(prev => prev + 1);  // Increase login count when checked in
    };

    const handleCheckOut = () => {
        setCheckedIn(false);
        const currentTime = new Date().toLocaleString();
        setCheckOutTime(currentTime);
    };

    const handleCountryClick = (country) => {
        navigate(`/users/${country}`);
    };

    // Filter the countries based on the search input
    const filteredCountries = usersByCountryData.filter(country => 
        country.country.toLowerCase().includes(search.toLowerCase())
    );

    useEffect(() => {
        // In a real application, you would fetch login count from the server/database
        // For now, let's assume it's stored in localStorage or some global state
        const storedLoginCount = localStorage.getItem('loginCount');
        if (storedLoginCount) {
            setLoginCount(Number(storedLoginCount));
        }
    }, []);

    useEffect(() => {
        // Update login count in localStorage
        localStorage.setItem('loginCount', loginCount);
    }, [loginCount]);

    return (
        <>
            <Typography variant="h4" gutterBottom>
                CRM Dashboard
            </Typography>

                 {/* Check-in/Check-out Section */}
                   {/* Check-in/Check-out Section */}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Employee Check-in / Check-out
                    </Typography>

                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            {checkedIn ? (
                                <Typography variant="body1" gutterBottom>
                                    Checked in at: {checkInTime}
                                </Typography>
                            ) : (
                                <Typography variant="body1" gutterBottom>
                                    You are currently not checked in.
                                </Typography>
                            )}
                        </Grid>

                        <Grid item>
                            {checkedIn ? (
                                <Button variant="contained" color="secondary" onClick={handleCheckOut}>
                                    Check-out
                                </Button>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleCheckIn}>
                                    Check-in
                                </Button>
                            )}
                        </Grid>
                    </Grid>

                    {checkOutTime && (
                        <Typography variant="body1">
                            Checked out at: {checkOutTime}
                        </Typography>
                    )}

                    <Typography variant="body1" gutterBottom>
                        Total Logins: {loginCount}
                    </Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2}>
                {/* Users by Country Section */}
                <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>
                        Users by Country
                    </Typography>

                    {/* Search Box for Filtering Countries */}
                    <TextField
                        label="Search Countries"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <Grid container spacing={3}>
                        {filteredCountries.map((data, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Card onClick={() => handleCountryClick(data.country)} style={{ cursor: 'pointer' }}>
                                    <CardContent>
                                        <Typography variant="h6">{data.country}</Typography>
                                        <Typography>Total Users: {data.users}</Typography>
                                        <Typography>Converted Users: {data.converted}</Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Sales Team Performance Section */}
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Sales Team Performance
                            </Typography>
                            <ResponsiveContainer width="100%" height={200}>
                                <LineChart data={salesData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="sales" stroke="#8884d8" />
                                </LineChart>
                            </ResponsiveContainer>
                            <DataTable data={salesData} title="Sales Team Members" />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Operations Team Performance Section */}
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Operations Team Performance
                            </Typography>
                            <ResponsiveContainer width="100%" height={200}>
                                <LineChart data={operationsData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="tasksCompleted" stroke="#82ca9d" />
                                </LineChart>
                            </ResponsiveContainer>
                            <DataTable data={operationsData} title="Operations Team Members" />
                        </CardContent>
                    </Card>
                </Grid>

                {/* Presales Team Performance Section */}
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" gutterBottom>
                                Presales Team Performance
                            </Typography>
                            <ResponsiveContainer width="100%" height={200}>
                                <LineChart data={presalesData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="leads" stroke="#ffc658" />
                                </LineChart>
                            </ResponsiveContainer>
                            <DataTable data={presalesData} title="Presales Team Members" />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

       
        </>
    );
};

export default DashboardScreen;
