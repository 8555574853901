import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  CircularProgress,Checkbox 
} from '@mui/material';
import axios from 'axios';
import { styled } from '@mui/system';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";  // Import CSS for datepicker
import baseUrl from '../../config';

const LeadTableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
}));

const AssignLeads = () => {
  const [leads, setLeads] = useState([]);
  const [assignedLeads, setAssignedLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [error, setError] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Fetch teams and leads on component mount or when the selected date changes
  useEffect(() => {
    const fetchTeamsAndLeads = async () => {
      setLoading(true);
      try {
        // Fetch teams
        const teamsResponse = await axios.get(baseUrl + 'api/manager/preteams', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
        });

        setTeams(teamsResponse.data.teams);

        // Fetch leads based on the selected date
        const params = selectedDate ? { selectedDate: selectedDate.toISOString() } : {};
        const leadsResponse = await axios.get(baseUrl + 'api/manager/latest-leads', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          params: {
            selectedDate: selectedDate ? selectedDate.toISOString() : null
          }
        });

        // Filter leads to exclude already assigned ones
        setLeads(leadsResponse.data.leads);
      } catch (err) {
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchTeamsAndLeads();
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value);
  };

  const handleLeadSelect = (leadId) => {
    setSelectedLeads((prevSelectedLeads) => {
      if (prevSelectedLeads.includes(leadId)) {
        return prevSelectedLeads.filter((id) => id !== leadId);
      } else if (prevSelectedLeads.length < 50) {
        return [...prevSelectedLeads, leadId];
      } else {
        setSnackbarMessage('You can only assign up to 50 leads.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return prevSelectedLeads;
      }
    });
  };

  const handleAssignLeads = async () => {
    if (!selectedTeam || selectedLeads.length === 0) {
      setSnackbarMessage('Please select a team and leads');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axios.post(baseUrl + `api/manager/assign-leads/${selectedTeam}`, {
        // teamId: selectedTeam,
        leadIds: selectedLeads,
      }, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
      });

      setSnackbarMessage(response.data.message);
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Update the list of assigned leads
      setAssignedLeads((prevAssignedLeads) => [
        ...prevAssignedLeads,
        ...leads.filter(lead => selectedLeads.includes(lead._id))
      ]);

      // Remove the assigned leads from the available leads list
      setLeads(leads.filter(lead => !selectedLeads.includes(lead._id)));

      // Reset selections
      setSelectedTeam('');
      setSelectedLeads([]);
    } catch (error) {
      setSnackbarMessage('Failed to assign leads. Try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom align="center">
        Assign Leads to Presales Team
      </Typography>

      {loading && <CircularProgress size={24} sx={{ display: 'block', margin: 'auto' }} />}
      {error && <Typography color="error" align="center">{error}</Typography>}

      {/* Date Picker */}
      <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: 3 }}>
        <Grid item>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select Date"
            customInput={<Button variant="outlined" color="primary">Select Date</Button>}
          />
        </Grid>

        {/* Team Selection */}
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel>Select Team</InputLabel>
            <Select
              value={selectedTeam}
              onChange={handleTeamChange}
              label="Select Team"
              fullWidth
            >
              {teams?.map((team) => (
                <MenuItem key={team?._id} value={team?._id}>
                  {team?.teamName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Available Leads */}
      <Typography variant="h6" gutterBottom>
        Available Leads
      </Typography>
      <LeadTableContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Parent</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Kid</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Country</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leads.map((lead) => (
                <TableRow key={lead._id}>
                  <TableCell>{lead.Parent}</TableCell>
                  <TableCell>{lead.Email}</TableCell>
                  <TableCell>{lead.Phone}</TableCell>
                  <TableCell>{lead.Kid}</TableCell>
                  <TableCell>{lead.Grade}</TableCell>
                  <TableCell>{lead.Country}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={selectedLeads.includes(lead._id)}
                      onChange={() => handleLeadSelect(lead._id)}
                      disabled={assignedLeads.some(assigned => assigned._id === lead._id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LeadTableContainer>

      {/* Assigned Leads */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
        Assigned Leads
      </Typography>
      <LeadTableContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Parent</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Kid</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Country</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assignedLeads.map((lead) => (
                <TableRow key={lead._id}>
                  <TableCell>{lead.Parent}</TableCell>
                  <TableCell>{lead.Email}</TableCell>
                  <TableCell>{lead.Phone}</TableCell>
                  <TableCell>{lead.Kid}</TableCell>
                  <TableCell>{lead.Grade}</TableCell>
                  <TableCell>{lead.Country}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </LeadTableContainer>

      {/* Assign Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAssignLeads}
          disabled={loading || selectedLeads.length === 0}
        >
          Assign Selected Leads
        </Button>
      </Box>

      {/* Snackbar for feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AssignLeads;
