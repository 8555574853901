// src/components/LeadCreatePage.js
import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  Divider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import DashboardLayout from '../../Component/Sidebar';
import { AddCircleOutline, CheckCircleOutline } from '@mui/icons-material';

// Styled components for improved design
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#e9eff5',
  borderRadius: '12px',
  boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}));

const FormGrid = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#3f51b5',
  '&:hover': {
    backgroundColor: '#283593',
  },
  padding: theme.spacing(1.5),
  textTransform: 'capitalize',
  width: '100%',
  fontWeight: 'bold',
  marginTop: theme.spacing(3),
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
}));

const FormTitle = styled(Typography)(({ theme }) => ({
  color: '#333',
  fontWeight: '600',
  fontSize: '2rem',
  marginBottom: theme.spacing(3),
  textAlign: 'center',
}));

const LeadCreatePage = ({ onCreateLead }) => {
  const [lead, setLead] = useState({
    name: '',
    mobileNumber: '',
    parentsName: '',
    email: '',
    grade: '',
    time: '',
    date: '',
    priority: '',
    leadSource: '',
  });
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLead((prevLead) => ({
      ...prevLead,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (onCreateLead) {
      onCreateLead(lead); // Call the passed function to handle the lead data
      setOpenDialog(true); // Show dialog after lead is created
    }
  };

  return (
    <Container  sx={{ width: '100%', borderRadius: '12px', boxShadow: '0px 10px 25px rgba(0, 0, 0, 0.1)' }}>
      <FormTitle variant="h4">Create New Lead</FormTitle>
      <Card>
        <CardContent>
          <form noValidate autoComplete="off">
            <Grid container spacing={3}>
              {/* Name, Mobile Number, Parent's Name, Email */}
              <FormGrid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={lead.name}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                />
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Mobile Number"
                  name="mobileNumber"
                  value={lead.mobileNumber}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                />
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Parent's Name"
                  name="parentsName"
                  value={lead.parentsName}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                />
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  value={lead.email}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                />
              </FormGrid>
              {/* Priority, Lead Source, Grade */}
              <FormGrid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Priority</InputLabel>
                  <Select
                    label="Priority"
                    name="priority"
                    value={lead.priority}
                    onChange={handleChange}
                    variant="outlined"
                    size="medium"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <MenuItem value="Low">Low</MenuItem>
                    <MenuItem value="Medium">Medium</MenuItem>
                    <MenuItem value="High">High</MenuItem>
                  </Select>
                </FormControl>
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Lead Source</InputLabel>
                  <Select
                    label="Lead Source"
                    name="leadSource"
                    value={lead.leadSource}
                    onChange={handleChange}
                    variant="outlined"
                    size="medium"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <MenuItem value="Referral">Referral</MenuItem>
                    <MenuItem value="Website">Website</MenuItem>
                    <MenuItem value="Event">Event</MenuItem>
                    <MenuItem value="Advertisement">Advertisement</MenuItem>
                  </Select>
                </FormControl>
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Grade</InputLabel>
                  <Select
                    label="Grade"
                    name="grade"
                    value={lead.grade}
                    onChange={handleChange}
                    variant="outlined"
                    size="medium"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                    <MenuItem value="D">D</MenuItem>
                  </Select>
                </FormControl>
              </FormGrid>
              {/* Preferred Time and Date */}
              <FormGrid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Preferred Time"
                  name="time"
                  value={lead.time}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                />
              </FormGrid>
              <FormGrid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Preferred Date"
                  name="date"
                  type="date"
                  value={lead.date}
                  onChange={handleChange}
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                />
              </FormGrid>
            </Grid>
            <SubmitButton onClick={handleSubmit}>
              <AddCircleOutline sx={{ mr: 1 }} />
              Create Lead
            </SubmitButton>
          </form>
        </CardContent>
      </Card>

      {/* Dialog Box to Show Lead Creation Success */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Lead Created Successfully</DialogTitle>
        <DialogContent>
          <Typography variant="body1" color="textSecondary">
            The lead has been successfully created and is now passed to the Operations team for scheduling a demo.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            variant="contained"
            style={{ fontWeight: 'bold' }}
          >
            <CheckCircleOutline sx={{ mr: 1 }} />
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LeadCreatePage;
