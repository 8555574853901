import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CssBaseline,
  Box,
  Container,
  Divider,
  Avatar,
  Tooltip,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText as MuiListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import useAuth from '../useAuth';

const drawerWidth = 250;

const DrawerContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(2),
}));

const SidebarItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
  },
  '&.active': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const SidebarIcon = styled(ListItemIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '1.5rem',
}));

const SidebarText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50%',
  width: '70px',
  height: '70px',
  margin: '0 auto',
}));

const DashboardLayout = ({ children, }) => {
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || '');

  const [mobileOpen, setMobileOpen] = useState(false);
  const [activeItem, setActiveItem] = useState('/dashboard');
  const [profileAnchorEl, setProfileAnchorEl] = useState(null); // For the profile dropdown
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null); // For the notification dropdown
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuItemClick = (path) => {
    setActiveItem(path);
  };
  const handleLogout = () => {
    // Clear user-related data from localStorage
    localStorage.removeItem('userRole');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
  
    // Reset the userRole state to reflect logged out state
    setUserRole('');
    window.location.reload();

    
    // Programmatically navigate to the login screen
    navigate('/');
  };
  
  useAuth()
  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget); // Open profile menu when avatar is clicked
  };

  const handleNotificationMenuOpen = (event) => {
    setNotificationAnchorEl(event.currentTarget); // Open notification menu when bell is clicked
  };

  const handleMenuClose = () => {
    setProfileAnchorEl(null);
    setNotificationAnchorEl(null); // Close both menus
  };

  const drawer = (
    <DrawerContent>
      <LogoWrapper>
        <Avatar
          alt="Logo"
          src="https://www.kasandbox.org/programming-images/avatars/leaf-blue.png"
          sx={{ width: 50, height: 50 }}
        />
      </LogoWrapper>

      <Divider sx={{ margin: 2 }} />

      <List>
        <SidebarItem
          button
          component={Link}
          to="/"
          className={activeItem === '/' ? 'active' : ''}
          onClick={() => handleMenuItemClick('/')}
        >
          <SidebarIcon>
            <DashboardIcon />
          </SidebarIcon>
          <SidebarText primary="Dashboard" />
        </SidebarItem>

        <SidebarItem
          button
          component={Link}
          to="/profile"
          className={activeItem === '/profile' ? 'active' : ''}
          onClick={() => handleMenuItemClick('/profile')}
        >
          <SidebarIcon>
            <AccountCircleIcon />
          </SidebarIcon>
          <SidebarText primary="Profile" />
        </SidebarItem>

        <SidebarItem
          button
          component={Link}
          to="/login"
          className={activeItem === '/logout' ? 'active' : ''}
          onClick={handleLogout}
        >
          <SidebarIcon>
            <ExitToAppIcon />
          </SidebarIcon>
          <SidebarText primary="Logout" />
        </SidebarItem>

        <Divider sx={{ margin: 2 }} />

        {userRole === 'presales' && (
          <>
            <SidebarItem
              button
              component={Link}
              to="/create-lead"
              className={activeItem === '/create-lead' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/create-lead')}
            >
              <SidebarText primary="Create Lead" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/lead-list"
              className={activeItem === '/lead-list' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-list')}
            >
              <SidebarText primary="Lead List" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/lead-pipeline"
              className={activeItem === '/lead-pipeline' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-pipeline')}
            >
              <SidebarText primary="Lead pipeline" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/lead-team"
              className={activeItem === '/lead-team' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-team')}
            >
              <SidebarText primary="Lead team" />
            </SidebarItem>
          </>
        )}

        {userRole === 'manager' && (
          <>
            <SidebarItem
              button
              component={Link}
              to="/sales-team"
              className={activeItem === '/sales-team' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/sales-team')}
            >
              
              <SidebarText primary="Sales Team" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/sales-team-list"
              className={activeItem === '/sales-team-list' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/sales-team-list')}
            >
                  <SidebarText primary="Sales Team List" />
                  </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/upload-lead"
              className={activeItem === '/upload-lead' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/upload-lead')}
            >
              <SidebarText primary="Upload Lead" />
            </SidebarItem>



            <SidebarItem
              button
              component={Link}
              to="/assign-lead"
              className={activeItem === '/assign-lead' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/assign-lead')}
            >
              <SidebarText primary="assign Lead" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/leadlist"
              className={activeItem === '/leadlist' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/leadlist')}
            >
              <SidebarText primary="Lead List" />
            </SidebarItem>


            <SidebarItem
              button
              component={Link}
              to="/operations-team"
              className={activeItem === '/operations-team' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/operations-team')}
            >
              <SidebarText primary="Operations Team" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/manager-dashboard"
              className={activeItem === '/manager-dashboard' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/manager-dashboard')}
            >
              <SidebarText primary="Manager Dashboard" />
            </SidebarItem>

            <SidebarItem
              button
              component={Link}
              to="/team-performance"
              className={activeItem === '/team-performance' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/team-performance')}
            >
              <SidebarText primary="Team Performance Management" />
            </SidebarItem>
          </>
        )}

        {userRole === 'operations' && (
          <>
            <SidebarItem
              button
              component={Link}
              to="/lead-opretion"
              className={activeItem === '/lead-opretion' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-opretion')}
            >
              <SidebarText primary="Lead Pipeline" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/lead-perform"
              className={activeItem === '/lead-perform' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-perform')}
            >
              <SidebarText primary="Lead Perform" />
            </SidebarItem>
            <SidebarItem
              button
              component={Link}
              to="/lead-activity"
              className={activeItem === '/lead-activity' ? 'active' : ''}
              onClick={() => handleMenuItemClick('/lead-activity')}
            >
              <SidebarText primary="Lead Activity" />
            </SidebarItem>
          </>
        )}
      </List>
    </DrawerContent>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: (theme) => theme.palette.primary.dark,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Oratrics CRM
          </Typography>

          {/* Notification Bell Icon */}
          <IconButton
            color="inherit"
            onClick={handleNotificationMenuOpen}
            sx={{ ml: 2 }}
          >
            <NotificationsIcon />
          </IconButton>

          {/* Notification Menu */}
          <Menu
            anchorEl={notificationAnchorEl}
            open={Boolean(notificationAnchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>New Lead Created</MenuItem>
            <MenuItem>Lead Follow-up Reminder</MenuItem>
            <MenuItem>Lead Status Updated</MenuItem>
          </Menu>

          {/* Profile Icon */}
          <IconButton
            color="inherit"
            onClick={handleProfileMenuOpen}
            sx={{ ml: 2 }}
          >
            <Avatar alt="User Name" src="https://www.kasandbox.org/programming-images/avatars/leaf-blue.png" />
          </IconButton>

          {/* Profile Menu */}
          <Menu
            anchorEl={profileAnchorEl}
            open={Boolean(profileAnchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <ListItemAvatar>
                <Avatar
                  alt="User Avatar"
                  src="https://www.kasandbox.org/programming-images/avatars/leaf-blue.png"
                />
              </ListItemAvatar>
              <MuiListItemText primary="John Doe" secondary="johndoe@example.com" />
            </MenuItem>
            <MenuItem>
              <MuiListItemText primary="Employee ID: 123456" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Improve performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { width: drawerWidth, marginTop: '64px' },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              position: 'fixed',
              top: '64px',
              transition: 'width 0.3s',
            },
          }}
          variant="persistent"
          anchor="left"
          open
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
        }}
      >
        <Toolbar />
        <Container>{children}</Container>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
