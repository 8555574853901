import React, { useState } from 'react';
import { Box, Paper, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from '@mui/lab';
import { Edit, Delete, Info } from '@mui/icons-material';
import dayjs from 'dayjs';

// Sample activities with more details for illustration
const activities = [
  { time: '2024-10-01', action: 'Lead Created', user: 'John Doe', type: 'create', details: 'Lead entered into CRM system.' },
  { time: '2024-10-02', action: 'Contacted Lead', user: 'Alice Johnson', type: 'contact', details: 'Initial call made to the lead.' },
  { time: '2024-10-03', action: 'Scheduled Meeting', user: 'Bob Brown', type: 'meeting', details: 'Demo scheduled for 2024-10-05.' },
  { time: '2024-10-05', action: 'Meeting Completed', user: 'John Doe', type: 'meeting', details: 'Lead expressed interest in product.' },
  { time: '2024-10-06', action: 'Demo Scheduled', user: 'Alice Johnson', type: 'schedule', details: 'Follow-up demo scheduled.' },
];

const ActivityItem = ({ activity, index, handleEdit, handleDelete }) => {
  const formatDate = (date) => dayjs(date).format('MMMM D, YYYY');

  return (
    <TimelineItem key={index}>
      <TimelineOppositeContent color="textSecondary">
        {formatDate(activity.time)}
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot color={activity.type === 'create' ? 'primary' : activity.type === 'contact' ? 'secondary' : 'success'} />
        {index < activities.length - 1 && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Paper sx={{ padding: 2, display: 'flex', flexDirection: 'column', position: 'relative', boxShadow: 2 }}>
          <Typography variant="body1" fontWeight="bold" color="textPrimary">
            {activity.action}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`Performed by: ${activity.user}`}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
            {activity.details}
          </Typography>

          {/* Action buttons placed in a flex container */}
          <Box sx={{
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 1, 
            mt: 1, 
            alignItems: 'center'
          }}>
            <Tooltip title="Edit Activity">
              <IconButton onClick={() => handleEdit(index)} color="primary">
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Activity">
              <IconButton onClick={() => handleDelete(index)} color="error">
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Details">
              <IconButton color="info">
                <Info />
              </IconButton>
            </Tooltip>
          </Box>
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

const LeadActivityTracker = ({ leadId }) => {
  // State to manage the activities
  const [activitiesState, setActivitiesState] = useState(activities);

  // Handle delete activity
  const handleDelete = (index) => {
    const newActivities = activitiesState.filter((_, i) => i !== index);
    setActivitiesState(newActivities);
  };

  // Handle edit activity (this can be expanded to show a dialog/modal)
  const handleEdit = (index) => {
    alert(`Edit activity at index ${index}`);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        Lead Activity Tracker
      </Typography>

      {/* Divider to separate the header */}
      <Divider sx={{ marginBottom: 2 }} />

      {/* Timeline */}
      <Timeline position="alternate">
        {activitiesState.map((activity, index) => (
          <ActivityItem
            key={index}
            activity={activity}
            index={index}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
          />
        ))}
      </Timeline>
    </Box>
  );
};

export default LeadActivityTracker;
