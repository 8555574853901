import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Card, Divider } from '@mui/material';
import { styled } from '@mui/system';
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Styled components for a clean layout
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f4f7fc',
  borderRadius: '12px',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: '#333',
  fontWeight: '700',
  fontSize: '2.2rem',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const DashboardCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(4),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

// Sample data for the dashboard
const OperationsDashboardPage = () => {
  const [data, setData] = useState({
    meetingsArranged: 120,
    demosCreated: 85,
    demosScheduled: 60,
    demosRescheduled: 25,
    teamPerformance: [
      { name: 'Alice', tasksCompleted: 45, meetingsHeld: 12, demosScheduled: 5 },
      { name: 'Bob', tasksCompleted: 50, meetingsHeld: 15, demosScheduled: 6 },
      { name: 'Charlie', tasksCompleted: 35, meetingsHeld: 10, demosScheduled: 4 },
    ],
    productivityStats: [
      { name: 'Week 1', productivity: 80 },
      { name: 'Week 2', productivity: 85 },
      { name: 'Week 3', productivity: 90 },
      { name: 'Week 4', productivity: 95 },
    ],
  });

  // Pie chart data for team performance
  const teamPerformanceData = [
    { name: 'Alice', value: data.teamPerformance[0].tasksCompleted + data.teamPerformance[0].meetingsHeld + data.teamPerformance[0].demosScheduled },
    { name: 'Bob', value: data.teamPerformance[1].tasksCompleted + data.teamPerformance[1].meetingsHeld + data.teamPerformance[1].demosScheduled },
    { name: 'Charlie', value: data.teamPerformance[2].tasksCompleted + data.teamPerformance[2].meetingsHeld + data.teamPerformance[2].demosScheduled },
  ];

  const pieData = [
    { name: 'Meetings Arranged', value: data.meetingsArranged },
    { name: 'Demos Created', value: data.demosCreated },
    { name: 'Demos Scheduled', value: data.demosScheduled },
    { name: 'Demos Rescheduled', value: data.demosRescheduled },
  ];

  const barData = [
    { name: 'Week 1', meetings: 40, demos: 30 },
    { name: 'Week 2', meetings: 30, demos: 35 },
    { name: 'Week 3', meetings: 35, demos: 40 },
    { name: 'Week 4', meetings: 15, demos: 20 },
  ];

  return (
    <Container>
      <Title>Operations Dashboard</Title>

      <Grid container spacing={4}>
        {/* Dashboard Card: Meetings Arranged */}
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard>
            <Box>
              <Typography variant="h6">Meetings Arranged</Typography>
              <Typography variant="h4" fontWeight="bold">{data.meetingsArranged}</Typography>
            </Box>
            <PieChart width={100} height={100}>
              <Pie data={pieData} dataKey="value" nameKey="name" outerRadius={40} fill="#8884d8">
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === 0 ? "#82ca9d" : "#8884d8"} />
                ))}
              </Pie>
            </PieChart>
          </DashboardCard>
        </Grid>

        {/* Dashboard Card: Demo Activities */}
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard>
            <Box>
              <Typography variant="h6">Demo Activities</Typography>
              <Typography variant="h4" fontWeight="bold">{data.demosCreated}</Typography>
            </Box>
            <BarChart width={350} height={250} data={barData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="meetings" fill="#82ca9d" />
              <Bar dataKey="demos" fill="#8884d8" />
            </BarChart>
          </DashboardCard>
        </Grid>

        {/* Dashboard Card: Demo Schedule Overview */}
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard>
            <Box>
              <Typography variant="h6">Demo Schedule Overview</Typography>
              <Typography variant="h4" fontWeight="bold">{data.demosScheduled}</Typography>
            </Box>
            <PieChart width={100} height={100}>
              <Pie data={pieData} dataKey="value" nameKey="name" outerRadius={40} fill="#8884d8">
                {pieData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={index === 2 ? "#82ca9d" : "#8884d8"} />
                ))}
              </Pie>
            </PieChart>
          </DashboardCard>
        </Grid>
      </Grid>

      <Divider sx={{ margin: 3 }} />

      {/* Team Performance Pie Chart */}
      <Grid item xs={12}>
        <Paper sx={{ padding: 3 }}>
          <Typography variant="h6" gutterBottom>Team Performance Overview</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie data={teamPerformanceData} dataKey="value" nameKey="name" outerRadius={100} fill="#8884d8">
                {teamPerformanceData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={['#82ca9d', '#ff7300', '#8884d8'][index]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>

      <Divider sx={{ margin: 3 }} />

      {/* Productivity Stats Over Time */}
      <Grid item xs={12}>
        <Paper sx={{ padding: 2 }}>
          <Typography variant="h6">Productivity Stats (Last 4 Weeks)</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data.productivityStats}>
              <CartesianGrid stroke="#ccc" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="productivity" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </Paper>
      </Grid>
    </Container>
  );
};

export default OperationsDashboardPage;
