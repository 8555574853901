import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarController, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary components for the Bar chart
ChartJS.register(
  BarController,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const TeamPerformanceMetrics = ({ teamMembers = [] }) => {
  // Default dummy data if no teamMembers are passed in
  const dummyData = [
    { name: 'John Doe', tasksCompleted: 10, meetingsScheduled: 5, leadsContacted: 15 },
    { name: 'Jane Smith', tasksCompleted: 7, meetingsScheduled: 6, leadsContacted: 10 },
    { name: 'Bob Brown', tasksCompleted: 8, meetingsScheduled: 3, leadsContacted: 12 },
    { name: 'Alice Johnson', tasksCompleted: 12, meetingsScheduled: 8, leadsContacted: 18 }
  ];

  // Prepare the chart data
  const performanceData = {
    labels: (teamMembers.length ? teamMembers : dummyData).map(member => member.name),
    datasets: [
      {
        label: 'Tasks Completed',
        data: (teamMembers.length ? teamMembers : dummyData).map(member => member.tasksCompleted),
        backgroundColor: 'rgba(79, 195, 247, 0.6)',
        borderColor: 'rgba(79, 195, 247, 1)',
        borderWidth: 1,
      },
      {
        label: 'Meetings Scheduled',
        data: (teamMembers.length ? teamMembers : dummyData).map(member => member.meetingsScheduled),
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Leads Contacted',
        data: (teamMembers.length ? teamMembers : dummyData).map(member => member.leadsContacted),
        backgroundColor: 'rgba(255, 159, 64, 0.6)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 1,
      },
    ],
  };

  return (
    <div style={{ padding: '16px' }}>
      <h6>Team Performance Metrics</h6>
      {teamMembers.length === 0 && <div>No team members available</div>} {/* Handle empty array */}
      <Bar data={performanceData} />
    </div>
  );
};

export default TeamPerformanceMetrics;
