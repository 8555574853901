import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter from react-router-dom

import { ThemeProvider, createTheme } from '@mui/material/styles';

import reportWebVitals from './reportWebVitals';
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Blue color as an example
    },
    secondary: {
      main: '#dc004e', // Pink color as an example
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <ThemeProvider theme={theme}>
        <BrowserRouter>  {/* Wrap your app with BrowserRouter */}

    <App />
    </BrowserRouter>
    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
