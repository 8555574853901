import React, { useState } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios'; // For sending requests
import * as XLSX from 'xlsx';
import baseUrl from '../../config';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  minHeight: '80vh',
}));

const UploadButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
  backgroundColor: '#1976d2',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#1565c0',
  },
}));

const LeadTableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '100%',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
}));

const UploadLeadData = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false); // To show loading state
  const [error, setError] = useState(null); // To handle error messages

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    
    if (file) {
      setLoading(true);
      setError(null);

      try {
        // Create a FormData object
        const formData = new FormData();
        formData.append('file', file);

        // Send the file to the server for processing
        const response = await axios.post(baseUrl + 'api/manager/uploadlead', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${localStorage.getItem('token')}`

          },
          
        });

        // Update the leads state with the uploaded data
        setLeads(response.data.leads);

      } catch (err) {
        setError('Error uploading leads: ' + err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Upload Lead Data
      </Typography>

      <UploadButton variant="contained" component="label">
        Upload Excel File
        <input type="file" accept=".xlsx, .xls" hidden onChange={handleFileUpload} />
      </UploadButton>

      {loading && <Typography variant="body1" align="center">Uploading...</Typography>}
      {error && <Typography variant="body1" align="center" color="error">{error}</Typography>}

      {/* Display the uploaded lead data */}
      {leads.length > 0 ? (
        <LeadTableContainer>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Parent</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Kid</TableCell>
                  <TableCell>Grade</TableCell>
                  <TableCell>Country</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.map((lead, index) => (
                  <TableRow key={index}>
                    <TableCell>{lead.Parent}</TableCell>
                    <TableCell>{lead.Email}</TableCell>
                    <TableCell>{lead.Phone}</TableCell>
                    <TableCell>{lead.Kid}</TableCell>
                    <TableCell>{lead.Grade}</TableCell>
                    <TableCell>{lead.Country}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LeadTableContainer>
      ) : (
        <Typography variant="body1" align="center">
          No lead data available. Please upload a valid Excel file.
        </Typography>
      )}
    </Container>
  );
};

export default UploadLeadData;
