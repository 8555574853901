import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute'; // Import PrivateRoute component

import LoginScreen from './auth/LoginScreen';
import SignupScreen from './auth/SignupScreen';
import LeadCreatePage from './Page/sales/LeadCreatePage';  
import LeadListPage from './Page/sales/LeadListPage';  
import SalesDashboard from './Page/sales/SalesDashboard'; 
import ManagerDashboard from './Page/manager/ManagerDashboard';  
import PreSalesTeamManagement from './Page/manager/PreSalesTeamManagement';  
import OperationsTeamManagement from './Page/manager/OperationsTeamManagement';  
import ProfileManagement from './Page/manager/ProfileManagement'; 
import TeamPerformanceManagement from './Page/manager/TeamPerformanceManagement';
import SalesMemberProfile from './Page/manager/SalesMemberProfile';
import OperationsMemberProfile from './Page/manager/OperationsMemberProfile';
import UserListPage from './Component/UserListPage';
import DashboardLayout from './Component/Sidebar';
import LeadPipelinePage from './Page/sales/LeadPipelinePage';
import PresalesTeamPerformancePage from './Page/sales/PresalesTeamPerformancePage';
import OperationsDashboardPage from './Page/oparetion/OperationsDashboardPage';
import OperationsLeadPipelinePage from './Page/oparetion/OperationsLeadPipelinePage';
import LeadActivityTracker from './Page/oparetion/LeadActivityTracker';
import TeamPerformanceMetrics from './Page/oparetion/TeamPerformanceMetrics';
import DashboardScreen from './Page/manager/dashboardscreen';
import UploadLeadData from './Page/manager/UploadLeadData';
import useAuth from './useAuth';
import PreTeamList from './Page/manager/PreTeamList';
import LeadList from './Page/manager/LeadList';
import AssignLeads from './Page/manager/AssginLeadToPresale';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem('token'));
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || '');

  console.log("App Rendered: isLoggedIn =", isLoggedIn, ", userRole =", userRole);

  const handleLoginSuccess = (role) => {
    setIsLoggedIn(true);
    setUserRole(role);
  };
  useAuth(); // Automatically checks if the user is authenticated
  // useEffect(() => {
  //   localStorage.removeItem('token');
  //   localStorage.removeItem('user');
  //   localStorage.removeItem('role');   
  // }, []);
  const [salesTeam] = useState([
    { id: 1, name: 'John Doe', totalSales: 1500 },
    { id: 2, name: 'Jane Smith', totalSales: 1200 },
  ]);
  const [operationsTeam] = useState([
    { id: 1, name: 'Alice Johnson', tasksCompleted: 50 },
    { id: 2, name: 'Bob Brown', tasksCompleted: 60 },
  ]);

  return (
    <Routes>
      {/* Route for Login */}
      <Route
        path="/login"
        element={isLoggedIn ? <Navigate to="/" /> : <LoginScreen onLoginSuccess={handleLoginSuccess} />}
      />

      {/* Protected Routes */}
      <Route element={<PrivateRoute isLoggedIn={isLoggedIn} role={userRole} allowedRoles={['manager']} />}>
        <Route path="/manager-dashboard" element={<DashboardLayout><DashboardScreen /></DashboardLayout>} />
        <Route path="/upload-lead" element={<DashboardLayout><UploadLeadData /></DashboardLayout>} />
        <Route path="/assign-lead" element={<DashboardLayout><AssignLeads /></DashboardLayout>} />
        <Route path="/leadlist" element={<DashboardLayout><LeadList /></DashboardLayout>} />
        <Route path="/sales-team" element={<DashboardLayout><PreSalesTeamManagement /></DashboardLayout>} />
        <Route path="/sales-team-list" element={<DashboardLayout><PreTeamList /></DashboardLayout>} />
        <Route path="/operations-team" element={<DashboardLayout><OperationsTeamManagement /></DashboardLayout>} />
        <Route path="/profile" element={<DashboardLayout><ProfileManagement /></DashboardLayout>} />
        <Route path="/team-performance" element={<DashboardLayout><TeamPerformanceManagement /></DashboardLayout>} />
        <Route path="/sales-member/:id" element={<DashboardLayout><SalesMemberProfile salesTeam={salesTeam} /></DashboardLayout>} />
        <Route path="/operations-member/:id" element={<DashboardLayout><OperationsMemberProfile operationsTeam={operationsTeam} /></DashboardLayout>} />
      </Route>

      {/* Sales Routes */}
      <Route element={<PrivateRoute isLoggedIn={isLoggedIn} role={userRole} allowedRoles={["presales"]} />}>
        <Route path="/presales-dashboard" element={<DashboardLayout><SalesDashboard /></DashboardLayout>} />
        <Route path="/create-lead" element={<DashboardLayout><LeadCreatePage /></DashboardLayout>} />
        <Route path="/lead-list" element={<DashboardLayout><LeadListPage /></DashboardLayout>} />
        <Route path="/lead-pipeline" element={<DashboardLayout><LeadPipelinePage /></DashboardLayout>} />
        <Route path="/lead-team" element={<DashboardLayout><PresalesTeamPerformancePage /></DashboardLayout>} />
      </Route>

      {/* Operations Routes */}
      <Route element={<PrivateRoute isLoggedIn={isLoggedIn} role={userRole} allowedRoles={["operations"]} />}>
        <Route path="/operations-dashboard" element={<DashboardLayout><OperationsDashboardPage /></DashboardLayout>} />
        <Route path="/lead-opretion" element={<DashboardLayout><OperationsLeadPipelinePage /></DashboardLayout>} />
        <Route path="/lead-activity" element={<DashboardLayout><LeadActivityTracker /></DashboardLayout>} />
        <Route path="/lead-perform" element={<DashboardLayout><TeamPerformanceMetrics /></DashboardLayout>} />
      </Route>

      {/* Default Route (Redirect to Dashboard or Login based on login status) */}
      <Route path="/" element={isLoggedIn ? <Navigate to={`/${userRole}-dashboard`} /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default App;
