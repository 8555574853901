import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, CircularProgress } from '@mui/material';
import axios from 'axios';
import baseUrl from '../../config';
import { styled } from '@mui/system';
import DatePicker from "react-datepicker";  // Import react-datepicker
import "react-datepicker/dist/react-datepicker.css";  // Import CSS for the datepicker

// Styled components
const LeadTableContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  backgroundColor: theme.palette.background.paper,
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),
  flexWrap: 'wrap',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  borderRadius: '6px',
  padding: '10px 20px',
  textTransform: 'none',
  fontSize: '16px',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
}));

const LeadCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  backgroundColor: theme.palette.background.default,
}));

const LeadList = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedDate, setSelectedDate] = useState(null);

  // Fetch leads data from the server
  useEffect(() => {
    const fetchLatestLeads = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = {
          page,
          limit,
          selectedDate: selectedDate ? selectedDate.toISOString() : undefined,
        };

        const response = await axios.get(baseUrl + 'api/manager/latest-leads', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          params,
        });

        setLeads(response.data.leads);
        setTotalPages(response.data.totalPages);
      } catch (err) {
        setError('Error fetching leads: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLatestLeads();
  }, [page, limit, selectedDate]);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: '#f8f8f8', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
        Latest Lead Data
      </Typography>

      {/* Loading Indicator */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Error Message */}
      {error && (
        <Typography variant="body1" align="center" color="error">
          {error}
        </Typography>
      )}

      {/* Date Picker Filter */}
      <FilterContainer>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          placeholderText="Select Date"
          style={{
            padding: '8px 12px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            width: '200px',
            fontSize: '16px',
            marginRight: '20px',
          }}
        />
        <StyledButton onClick={() => { setPage(1); }}>Filter</StyledButton>
      </FilterContainer>

      {/* Lead Data Table */}
      {leads.length > 0 ? (
        <LeadTableContainer>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Parent</strong></TableCell>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Phone</strong></TableCell>
                  <TableCell><strong>Kid</strong></TableCell>
                  <TableCell><strong>Grade</strong></TableCell>
                  <TableCell><strong>Country</strong></TableCell>
                  <TableCell><strong>Upload Date</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.map((lead, index) => (
                  <TableRow key={index}>
                    <TableCell>{lead.Parent}</TableCell>
                    <TableCell>{lead.Email}</TableCell>
                    <TableCell>{lead.Phone}</TableCell>
                    <TableCell>{lead.Kid}</TableCell>
                    <TableCell>{lead.Grade}</TableCell>
                    <TableCell>{lead.Country}</TableCell>
                    <TableCell>{new Date(lead.uploadedAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LeadTableContainer>
      ) : (
        <Typography variant="body1" align="center" sx={{ marginTop: 2 }}>
          No lead data available for the selected date.
        </Typography>
      )}

      {/* Pagination Controls */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
        <StyledButton disabled={page === 1} onClick={() => setPage(page - 1)}>
          Previous
        </StyledButton>
        <Typography variant="body1" sx={{ margin: '0 15px', alignSelf: 'center' }}>
          Page {page} of {totalPages}
        </Typography>
        <StyledButton disabled={page === totalPages} onClick={() => setPage(page + 1)}>
          Next
        </StyledButton>
      </Box>
    </Box>
  );
};

export default LeadList;
