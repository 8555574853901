// src/components/SignupScreen.js
import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import { signup } from '../api';
// import logo from '../logo.png'; // Adjust the path as necessary

const GradientContainer = styled(Box)(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'linear-gradient(135deg, #6e7dff, #8d4ff5)',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
  textAlign: 'center',
}));

const Logo = styled('img')({
  width: '100px', // Adjust size as needed
  marginBottom: '20px',
});

const SignupScreen = ({ onSignupSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignup = async () => {
    try {
      const response = await signup({ email, password });
      onSignupSuccess(response.data);
    } catch (error) {
      console.error('Signup failed', error);
    }
  };

  return (
    <GradientContainer>
      <FormContainer>
        {/* <Logo src={logo} alt="Logo" /> */}
        <Typography variant="h5" gutterBottom>Signup</Typography>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button onClick={handleSignup} variant="contained" color="primary" fullWidth>
          Signup
        </Button>
      </FormContainer>
    </GradientContainer>
  );
};

export default SignupScreen;
