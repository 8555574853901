// src/components/ProfileManagement.js
import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
import DashboardLayout from '../../Component/Sidebar';

const CardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: '#fafafa',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
}));

const ProfileManagement = () => {
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        role: 'Member', // Example role
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSaveProfile = () => {
        console.log('Profile updated:', profile);
    };

    return (
        <>
            <Box padding={4}>
                <Typography variant="h4" gutterBottom>
                    Profile Management
                </Typography>

                <CardContainer>
                    <CardContent>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            value={profile.name}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            name="email"
                            value={profile.email}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Role"
                            name="role"
                            value={profile.role}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSaveProfile}
                            style={{ marginTop: '20px' }}
                        >
                            Save Changes
                        </Button>
                    </CardContent>
                </CardContainer>
            </Box>
        </>
    );
};

export default ProfileManagement;
