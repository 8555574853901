// src/components/ManagerDashboard.js
import React, { useState } from 'react';
import { Button, Grid, Typography, Box, Card, CardContent, Badge } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../../Component/Sidebar';
import { styled } from '@mui/system';

// Simulated team data with 'isActive' status
const salesTeam = [
    { id: 1, name: 'John Doe', isActive: true },
    { id: 2, name: 'Jane Smith', isActive: false },
    { id: 3, name: 'Michael Johnson', isActive: true },
];

const operationsTeam = [
    { id: 1, name: 'Alice Johnson', isActive: true },
    { id: 2, name: 'Bob Brown', isActive: false },
];

const CardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: '#fafafa',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    cursor: 'pointer',
    '&:hover': {
        boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.2)',
    },
}));

const ManagerDashboard = () => {
    const navigate = useNavigate();

    // Function to handle navigation to sales or operations
    const handleGoToSales = () => {
        navigate('/sales-team');
    };

    const handleGoToOperations = () => {
        navigate('/operations-team');
    };

    return (
            <Box padding={4}>
                <Typography variant="h4" gutterBottom>
                    Supreme Manager Dashboard
                </Typography>

                {/* Sales Team Section */}
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <CardContainer onClick={handleGoToSales}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Manage Sales Team
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Create, Remove Sales Team and Members
                                </Typography>
                            </CardContent>
                        </CardContainer>
                    </Grid>

                    {/* Operations Team Section */}
                    <Grid item xs={12} md={6}>
                        <CardContainer onClick={handleGoToOperations}>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Manage Operations Team
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Create, Remove Operations Team and Members
                                </Typography>
                            </CardContent>
                        </CardContainer>
                    </Grid>
                </Grid>

                {/* Active/Inactive Status Section */}
                <Typography variant="h5" gutterBottom>
                    Team Member Status
                </Typography>

                {/* Sales Team Member Status */}
                <CardContainer>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Sales Team
                        </Typography>
                        <Grid container spacing={3}>
                            {salesTeam.map((member) => (
                                <Grid item xs={12} sm={6} key={member.id}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body1">{member.name}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Badge
                                                        color={member.isActive ? 'primary' : 'error'}
                                                        badgeContent={member.isActive ? 'Active' : 'Inactive'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </CardContainer>

                {/* Operations Team Member Status */}
                <CardContainer>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Operations Team
                        </Typography>
                        <Grid container spacing={3}>
                            {operationsTeam.map((member) => (
                                <Grid item xs={12} sm={6} key={member.id}>
                                    <Card>
                                        <CardContent>
                                            <Grid container justifyContent="space-between" alignItems="center">
                                                <Grid item>
                                                    <Typography variant="body1">{member.name}</Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Badge
                                                        color={member.isActive ? 'primary' : 'error'}
                                                        badgeContent={member.isActive ? 'Active' : 'Inactive'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </CardContainer>
            </Box>
    );
};

export default ManagerDashboard;
