import React, { useState } from 'react';
import {
  Card, CardContent, Grid, Typography, Box, Button, AppBar, Tabs, Tab, Container, Divider
} from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { styled } from '@mui/system';
import DashboardLayout from '../../Component/Sidebar';

// Styled components for a clean and professional look
const CardContainer = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  transition: 'box-shadow 0.3s ease, transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: '0px 12px 32px rgba(0, 0, 0, 0.15)',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: '#333',
  marginBottom: theme.spacing(2),
  fontSize: '24px',
  textAlign: 'center',
}));

const SubTitle = styled(Typography)(({ theme }) => ({
  color: '#444',
  fontWeight: 500,
  marginBottom: theme.spacing(2),
  fontSize: '18px',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  backgroundColor: '#007BFF',
  color: '#fff',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
  padding: theme.spacing(1.2, 3),
  borderRadius: '8px',
}));

const ChartContainer = styled('div')({
  height: '220px',
  marginTop: '20px',
  marginBottom: '20px',
});

// Tab Panel to handle content
const TabPanel = styled('div')({
  padding: '20px',
});

const TeamPerformanceManagement = () => {
  const [salesTeams, setSalesTeams] = useState([
    {
      teamName: 'Sales Team A',
      members: [
        { name: 'John Doe', totalSales: 1500, dailySales: [{ date: '2024-11-01', sales: 100 }, { date: '2024-11-02', sales: 150 }] },
        { name: 'Jane Smith', totalSales: 1200, dailySales: [{ date: '2024-11-01', sales: 90 }, { date: '2024-11-02', sales: 120 }] },
      ],
    },
    {
      teamName: 'Sales Team B',
      members: [
        { name: 'Mike Johnson', totalSales: 1600, dailySales: [{ date: '2024-11-01', sales: 200 }, { date: '2024-11-02', sales: 250 }] },
        { name: 'Sally Brown', totalSales: 1300, dailySales: [{ date: '2024-11-01', sales: 110 }, { date: '2024-11-02', sales: 140 }] },
      ],
    },
  ]);

  const [operationsTeams, setOperationsTeams] = useState([
    {
      teamName: 'Operations Team A',
      members: [
        { name: 'Alice Johnson', tasksCompleted: 50, dailyTasks: [{ date: '2024-11-01', tasks: 5 }, { date: '2024-11-02', tasks: 7 }] },
        { name: 'Bob Brown', tasksCompleted: 60, dailyTasks: [{ date: '2024-11-01', tasks: 4 }, { date: '2024-11-02', tasks: 8 }] },
      ],
    },
    {
      teamName: 'Operations Team B',
      members: [
        { name: 'Cathy Green', tasksCompleted: 80, dailyTasks: [{ date: '2024-11-01', tasks: 6 }, { date: '2024-11-02', tasks: 9 }] },
        { name: 'David Blue', tasksCompleted: 90, dailyTasks: [{ date: '2024-11-01', tasks: 7 }, { date: '2024-11-02', tasks: 10 }] },
      ],
    },
  ]);

  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Helper function to calculate the active percentage for a sales team
  const calculateSalesTeamActivePercentage = (team) => {
    const totalSales = team.members.reduce((sum, member) => sum + member.totalSales, 0);
    if (totalSales === 0) return 0;
    const activeSales = team.members.reduce((sum, member) => sum + member.totalSales, 0);
    return ((activeSales / totalSales) * 100).toFixed(2);
  };

  // Helper function to calculate the active percentage for an operations team
  const calculateOperationsTeamActivePercentage = (team) => {
    const totalTasks = team.members.reduce((sum, member) => sum + member.tasksCompleted, 0);
    if (totalTasks === 0) return 0;
    const activeTasks = team.members.reduce((sum, member) => sum + member.tasksCompleted, 0);
    return ((activeTasks / totalTasks) * 100).toFixed(2);
  };

  const handleRemoveSalesMember = (teamName, memberName) => {
    const updatedSalesTeams = salesTeams.map(team => {
      if (team.teamName === teamName) {
        team.members = team.members.filter(member => member.name !== memberName);
      }
      return team;
    });
    setSalesTeams(updatedSalesTeams);
  };

  const handleRemoveOperationsMember = (teamName, memberName) => {
    const updatedOperationsTeams = operationsTeams.map(team => {
      if (team.teamName === teamName) {
        team.members = team.members.filter(member => member.name !== memberName);
      }
      return team;
    });
    setOperationsTeams(updatedOperationsTeams);
  };

  return (
    <>
      <Box padding={3}>
        <Title variant="h4">Team Performance</Title>

        {/* Tab Navigation for Sales and Operations Teams */}
        <AppBar position="static" sx={{ backgroundColor: '#f5f5f5' }}>
          <Tabs 
            value={selectedTab} 
            onChange={handleTabChange} 
            aria-label="team selection tabs" 
            centered 
            sx={{
              '& .MuiTab-root': {
                color: '#555',
                fontWeight: 500,
              },
              '& .Mui-selected': {
                color: '#007BFF', // Highlight color for selected tab
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#007BFF', // Indicator color
              },
            }}
          >
            <Tab label="Sales Teams" />
            <Tab label="Operations Teams" />
          </Tabs>
        </AppBar>

        {/* Sales Team Performance */}
        {selectedTab === 0 && (
          <TabPanel>
            {salesTeams.map((salesTeam, index) => (
              <CardContainer key={index}>
                <CardContent>
                  <SubTitle variant="h6">{salesTeam.teamName}</SubTitle>
                  <Typography variant="body1" color="primary">
                    Active Percentage: {calculateSalesTeamActivePercentage(salesTeam)}%
                  </Typography>
                  <Grid container spacing={3}>
                    {salesTeam.members.map((member, idx) => (
                      <Grid item xs={12} sm={6} key={idx}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="body1" color="primary">{member.name}</Typography>
                            <Typography variant="body2" color="textSecondary">Total Sales: ${member.totalSales}</Typography>

                            <ChartContainer>
                              <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={member.dailySales}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="date" />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />
                                  <Line type="monotone" dataKey="sales" stroke="#8884d8" />
                                </LineChart>
                              </ResponsiveContainer>
                            </ChartContainer>

                            <ActionButton variant="outlined" onClick={() => handleRemoveSalesMember(salesTeam.teamName, member.name)}>
                              Remove Member
                            </ActionButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </CardContainer>
            ))}
          </TabPanel>
        )}

        {/* Operations Team Performance */}
        {selectedTab === 1 && (
          <TabPanel>
            {operationsTeams.map((operationsTeam, index) => (
              <CardContainer key={index}>
                <CardContent>
                  <SubTitle variant="h6">{operationsTeam.teamName}</SubTitle>
                  <Typography variant="body1" color="primary">
                    Active Percentage: {calculateOperationsTeamActivePercentage(operationsTeam)}%
                  </Typography>
                  <Grid container spacing={3}>
                    {operationsTeam.members.map((member, idx) => (
                      <Grid item xs={12} sm={6} key={idx}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="body1" color="primary">{member.name}</Typography>
                            <Typography variant="body2" color="textSecondary">Tasks Completed: {member.tasksCompleted}</Typography>

                            <ChartContainer>
                              <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={member.dailyTasks}>
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="date" />
                                  <YAxis />
                                  <Tooltip />
                                  <Legend />
                                  <Line type="monotone" dataKey="tasks" stroke="#82ca9d" />
                                </LineChart>
                              </ResponsiveContainer>
                            </ChartContainer>

                            <ActionButton variant="outlined" onClick={() => handleRemoveOperationsMember(operationsTeam.teamName, member.name)}>
                              Remove Member
                            </ActionButton>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </CardContainer>
            ))}
          </TabPanel>
        )}
      </Box>
    </>
  );
};

export default TeamPerformanceManagement;
