// src/components/PreSalesTeamPerformancePage.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, LinearProgress, List, ListItem, ListItemText, Divider } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';

const PreSalesTeamPerformancePage = () => {
  const [salesTeamData, setSalesTeamData] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [goalTrackingData, setGoalTrackingData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch all sales team data, leaderboard, and goal tracking
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [membersRes, leaderboardRes, goalTrackingRes] = await Promise.all([
          axios.get('/api/sales-team'),
          axios.get('/api/leaderboard'),
          axios.get('/api/goal-tracking'),
        ]);

        setSalesTeamData(membersRes.data.members);
        setLeaderboard(leaderboardRes.data.leaderboard);
        setGoalTrackingData(goalTrackingRes.data.goalTrackingData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sales data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const goalTrackingChartData = {
    labels: goalTrackingData.map((data) => data.name),
    datasets: [
      {
        label: 'Sales Target vs Actual',
        data: goalTrackingData.map((data) => data.salesPercentage),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 3 }}>
        Pre-Sales Team Performance
      </Typography>

      {/* Leaderboard Section */}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" fontWeight="bold">Leaderboard</Typography>
              <List>
                {leaderboard.map((member, index) => (
                  <ListItem key={member.id}>
                    <ListItemText primary={`${index + 1}. ${member.name}`} secondary={`Total Sales: $${member.totalSales}`} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Goal Tracking Section */}
        <Grid item xs={12} sm={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" fontWeight="bold">Goal Tracking</Typography>
              <Bar data={goalTrackingChartData} options={{ responsive: true }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Sales Metrics */}
      <Grid container spacing={3}>
        {salesTeamData.map((member) => (
          <Grid item xs={12} sm={6} md={3} key={member.id}>
            <Card>
              <CardContent>
                <Typography variant="h6" fontWeight="bold">{member.name}</Typography>
                <Typography variant="body1">Total Sales: ${member.performance.salesAchieved}</Typography>
                <Typography variant="body1">Leads Assigned: {member.performance.leadsAssigned}</Typography>
                <Typography variant="body1">Leads Closed: {member.performance.leadsClosed}</Typography>
                <LinearProgress variant="determinate" value={(member.performance.salesAchieved / 10000) * 100} />
                <Typography variant="body2" color="textSecondary">Progress to Target</Typography>
                <Divider sx={{ margin: '10px 0' }} />
                <Typography variant="body1">Calls Made: {member.performance.callsMade}</Typography>
                <Typography variant="body1">Meetings: {member.performance.meetings}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PreSalesTeamPerformancePage;
