export const usersDataByCountry = {
    USA: [
        {
            id: 1,
            name: 'John Doe',
            role: 'Sales Rep',
            status: 'Active',
            avatar: '/avatars/john.jpg',
            email: 'john.doe@example.com',
            parentName: 'Sarah Doe',
            grade: 'A',
            phoneNumber: '+1 555-1234',
            timezone: 'GMT-5',
        },
        {
            id: 2,
            name: 'Jane Smith',
            role: 'Operations Rep',
            status: 'Inactive',
            avatar: '/avatars/jane.jpg',
            email: 'jane.smith@example.com',
            parentName: 'James Smith',
            grade: 'B',
            phoneNumber: '+1 555-5678',
            timezone: 'GMT-5',
        },
        // Add more users as needed...
    ],
    UK: [
        {
            id: 1,
            name: 'Mike Wilson',
            role: 'Presales Consultant',
            status: 'Active',
            avatar: '/avatars/mike.jpg',
            email: 'mike.wilson@example.com',
            parentName: 'Linda Wilson',
            grade: 'A+',
            phoneNumber: '+44 20 7946 0958',
            timezone: 'GMT+0',
        },
        {
            id: 2,
            name: 'Emily Davis',
            role: 'Sales Rep',
            status: 'Active',
            avatar: '/avatars/emily.jpg',
            email: 'emily.davis@example.com',
            parentName: 'Michael Davis',
            grade: 'B+',
            phoneNumber: '+44 20 7946 0959',
            timezone: 'GMT+0',
        },
        // Add more users as needed...
    ],
    // Add more countries as needed...
};
