// src/api.js
import axios from 'axios';

const API_URL = 'http://yourapi.com/api'; // Replace with your API URL

export const login = (credentials) => {
  return axios.post(`${API_URL}/login`, credentials);
};

export const signup = (userData) => {
  return axios.post(`${API_URL}/signup`, userData);
};
