import React, { useState } from 'react';
import { Box, Typography, Paper, Card, CardContent, IconButton, Button } from '@mui/material';
import { styled } from '@mui/system';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CheckCircle, Phone, MeetingRoom, MoreVert, Replay, PersonAdd } from '@mui/icons-material';

// Styled components for improved design
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f4f7fc',
  borderRadius: '12px',
  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  minHeight: '80vh',
}));

const Title = styled(Typography)(({ theme }) => ({
  color: '#333',
  fontWeight: '700',
  fontSize: '2.2rem',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
}));

const StageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  marginTop: theme.spacing(3),
  flexWrap: 'wrap',
}));

const Stage = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: '1 1 22%', // This ensures each stage takes 22% of the container width
  backgroundColor: '#ffffff',
  borderRadius: '12px',
  boxShadow: '0 8px 24px rgba(0, 0, 0, 0.05)',
  minHeight: '350px',
  overflowY: 'auto',
  '&:hover': {
    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.1)',
  },
  // Ensure responsive design by reducing width on smaller screens
  '@media (max-width: 600px)': {
    flex: '1 1 48%',
  },
  '@media (max-width: 400px)': {
    flex: '1 1 100%',
  },
}));

const LeadCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  cursor: 'pointer',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
  borderRadius: '12px',
  backgroundColor: '#f9f9f9',
  padding: theme.spacing(2),
  '&:hover': {
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  color: '#333',
}));

const CardDetails = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#555',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  fontWeight: 'bold',
  textTransform: 'capitalize',
  marginTop: theme.spacing(1),
  borderRadius: '8px',
}));

const OperationsLeadPipelinePage = () => {
  // Dummy lead data with statuses
  const [leads, setLeads] = useState([
    { id: '1', name: 'John Doe', email: 'johndoe@example.com', phone: '+1234567890', priority: 'High', status: 'Created' },
    { id: '2', name: 'Jane Smith', email: 'janesmith@example.com', phone: '+9876543210', priority: 'Medium', status: 'Contacted' },
    { id: '3', name: 'Samuel Green', email: 'samuelgreen@example.com', phone: '+1122334455', priority: 'Low', status: 'Created' },
    { id: '4', name: 'Alice Brown', email: 'alicebrown@example.com', phone: '+9988776655', priority: 'Medium', status: 'Meeting Arranged' },
    { id: '5', name: 'Mark Johnson', email: 'markjohnson@example.com', phone: '+1223344556', priority: 'High', status: 'Created' },
  ]);

  const stages = ['Created', 'Contacted', 'Meeting Arranged', 'Meeting Completed'];

  // Handle the drag and drop functionality
  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return; // If dropped outside the list, do nothing

    const updatedLeads = [...leads];
    const [draggedLead] = updatedLeads.splice(source.index, 1);
    draggedLead.status = destination.droppableId; // Update the status to the new stage

    updatedLeads.splice(destination.index, 0, draggedLead);
    setLeads(updatedLeads);
  };

  // Get the leads grouped by their status
  const groupedLeads = stages.reduce((acc, stage) => {
    acc[stage] = leads.filter((lead) => lead.status === stage);
    return acc;
  }, {});

  // Function to handle Reschedule Meeting
  const handleRescheduleMeeting = (leadId) => {
    alert(`Rescheduling meeting for Lead ID: ${leadId}`);
  };

  // Function to handle Arrange Teacher for Demo Meeting
  const handleArrangeTeacherForDemo = (leadId) => {
    alert(`Arranging teacher for demo meeting for Lead ID: ${leadId}`);
  };

  return (
    <Container>
      <Title>Operations Lead Pipeline</Title>

      <DragDropContext onDragEnd={onDragEnd}>
        <StageContainer>
          {stages.map((stage) => (
            <Droppable droppableId={stage} key={stage}>
              {(provided) => (
                <Stage {...provided.droppableProps} ref={provided.innerRef}>
                  <Typography variant="h6" gutterBottom fontWeight="bold">
                    {stage} ({groupedLeads[stage].length} Leads)
                  </Typography>

                  {groupedLeads[stage].map((lead, index) => (
                    <Draggable key={lead.id} draggableId={lead.id} index={index}>
                      {(provided) => (
                        <LeadCard
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <CardHeader>
                            <CardTitle>{lead.name}</CardTitle>
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          </CardHeader>

                          <CardDetails>Email: {lead.email}</CardDetails>
                          <CardDetails>Phone: {lead.phone}</CardDetails>
                          <CardDetails>Priority: {lead.priority}</CardDetails>

                          {/* Action Buttons */}
                          {stage === 'Created' && (
                            <ActionButton variant="contained" color="primary" startIcon={<Phone />}>
                              Contact Lead
                            </ActionButton>
                          )}
                          {stage === 'Contacted' && (
                            <ActionButton variant="contained" color="success" startIcon={<MeetingRoom />}>
                              Arrange Meeting
                            </ActionButton>
                          )}
                          {stage === 'Meeting Arranged' && (
                            <>
                              <ActionButton variant="outlined" color="secondary" startIcon={<CheckCircle />}>
                                Confirm Meeting
                              </ActionButton>
                              <ActionButton
                                variant="contained"
                                color="warning"
                                startIcon={<Replay />}
                                onClick={() => handleRescheduleMeeting(lead.id)}
                              >
                                Reschedule Meeting
                              </ActionButton>
                              <ActionButton
                                variant="contained"
                                color="info"
                                startIcon={<PersonAdd />}
                                onClick={() => handleArrangeTeacherForDemo(lead.id)}
                              >
                                Arrange Teacher for Demo
                              </ActionButton>
                            </>
                          )}
                        </LeadCard>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </Stage>
              )}
            </Droppable>
          ))}
        </StageContainer>
      </DragDropContext>
    </Container>
  );
};

export default OperationsLeadPipelinePage;
