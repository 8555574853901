import React, { useState } from 'react';
import { Button, Grid, TextField, Typography, Box, Card, CardContent, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import DashboardLayout from '../../Component/Sidebar';

// Styled components
const CardContainer = styled(Card)(({ theme }) => ({
    backgroundColor: '#fafafa',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
}));

const OperationsTeamManagement = () => {
    const [teamName, setTeamName] = useState('');
    const [members, setMembers] = useState([]); // Team members
    const [allMembers, setAllMembers] = useState([  // All available members to choose from
        { id: 1, name: 'Alice', role: 'Operations' },
        { id: 2, name: 'Bob', role: 'Developer' },
        { id: 3, name: 'Charlie', role: 'Operations' },
        { id: 4, name: 'David', role: 'Tester' },
        { id: 5, name: 'Eve', role: 'Operations' },
        { id: 6, name: 'Frank', role: 'Support' },
        { id: 7, name: 'Grace', role: 'Operations' },
        { id: 8, name: 'Hannah', role: 'Developer' },
        { id: 9, name: 'Ivy', role: 'Designer' },
    ]);
    const [assignedMembers, setAssignedMembers] = useState([]); // Members assigned to any team

    const handleAddMember = (member) => {
        if (members.length < 5) {
            setMembers([...members, member]);
            setAssignedMembers([...assignedMembers, member.id]); // Mark member as assigned
        } else {
            alert("A team can only have 5 members.");
        }
    };

    const handleRemoveMember = (index) => {
        const newMembers = [...members];
        const removedMember = newMembers.splice(index, 1);
        setMembers(newMembers);
        setAssignedMembers(assignedMembers.filter(id => id !== removedMember[0].id)); // Unassign member
    };

    const handleCreateOperationsTeam = () => {
        if (members.length === 0) {
            alert("Please add at least one member to the team.");
        } else {
            // Simulate team creation (you can send this data to backend in real-world apps)
            console.log('Operations team created:', teamName, members);
        }
    };

    // Filter members to show only those with "Operations" role who are not assigned to a team
    const availableMembers = allMembers.filter(member => 
        member.role === 'Operations' && !assignedMembers.includes(member.id)
    );

    return (
        <>
            <Box padding={4}>
                <Typography variant="h4" gutterBottom>
                    Operations Team Management
                </Typography>

                <CardContainer>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Create Operations Team
                        </Typography>
                        <TextField
                            fullWidth
                            label="Operations Team Name"
                            value={teamName}
                            onChange={(e) => setTeamName(e.target.value)}
                            variant="outlined"
                            margin="normal"
                        />
                        <Typography variant="h6" gutterBottom>
                            Add Team Members
                        </Typography>
                        {/* List selected team members */}
                        {members.map((member, index) => (
                            <Grid container spacing={3} key={index}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Member Name"
                                        value={member.name}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label="Role"
                                        value={member.role}
                                        variant="outlined"
                                        margin="normal"
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        onClick={() => handleRemoveMember(index)}
                                        style={{ marginTop: '20px' }}
                                    >
                                        Remove Member
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}

                        {/* Available members dropdown */}
                        {members.length < 5 && (
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel>Select Member</InputLabel>
                                <Select
                                    value=""
                                    onChange={(e) => handleAddMember(availableMembers.find(m => m.id === e.target.value))}
                                    label="Select Member"
                                >
                                    {availableMembers.map((member) => (
                                        <MenuItem key={member.id} value={member.id}>
                                            {member.name} ({member.role})
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}

                        {/* Action Buttons */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCreateOperationsTeam}
                            style={{ marginTop: '20px' }}
                        >
                            Create Operations Team
                        </Button>
                    </CardContent>
                </CardContainer>
            </Box>
        </>
    );
};

export default OperationsTeamManagement;
