import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Card, CardContent, Button, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios'; // Import axios for making HTTP requests
import baseUrl from '../../config';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#f9f9f9',
  borderRadius: '12px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  minHeight: '80vh',
}));

const StageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  gap: theme.spacing(2),
}));

const LeadStage = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  width: '23%',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  minHeight: '300px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}));

const LeadCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  borderRadius: '8px',
  backgroundColor: '#f5f5f5',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: '#e3f2fd',
  },
}));

const CardContentStyled = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(1),
  '&:last-child': {
    paddingBottom: theme.spacing(1),
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

const LeadPipelinePage = () => {
  const [leads, setLeads] = useState([]);  // Initialize with empty array for dynamic data
  const stages = ['New', 'Contacted', 'Negotiating', 'Closed', 'Operations'];
  const mamber = JSON.parse(localStorage.getItem('user')); // Parse the user data from JSON

  console.log(leads, "leads");  

  // Fetch the leads from the API
  useEffect(() => {
    const fetchLeads = async () => {
      try {
        const response = await axios.get(`${baseUrl}api/presales/leads/${mamber.id}`,{ headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }});  // Adjust memberId if needed
        if (response.data.leadsAssigned) {
          setLeads(response.data.leadsAssigned);  // Set dynamic data
        }
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, []);  // Empty dependency array ensures this runs only once after the initial render

  const onDragEnd = async (result) => {
    const { destination, source } = result;

    if (!destination) return;

    const updatedLeads = [...leads];
    const [draggedLead] = updatedLeads.splice(source.index, 1);
    draggedLead.status = destination.droppableId;

    updatedLeads.splice(destination.index, 0, draggedLead);
    setLeads(updatedLeads);

    
    // Call API to update lead status on the server
    await axios.patch(`${baseUrl}api/presales/leads/${draggedLead._id}`, 
      { status: draggedLead.status },
      { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
    );
  };

  const leadsGroupedByStatus = () => {
    return stages.reduce((acc, stage) => {
      acc[stage] = leads.filter((lead) => lead.status === stage);
      return acc;
    }, {});
  };

  const groupedLeads = leadsGroupedByStatus();
  console.log(groupedLeads,"groupedLeads");


  const updateLeadStatus = async (leadId, newStatus, negotiationProgress = null) => {
    try {
      // Define the action to send based on the new status
      let action = '';
  
      if (newStatus === 'Negotiating') {
        // Action for when the negotiation starts
        action = negotiationProgress ? 'negotiationSuccess' : 'negotiationFailed';
      } else {
        action = newStatus;
      }
  
      const response = await axios.patch(
        `${baseUrl}api/presales/leads/${leadId}`,
        { action, negotiationProgress }, // Send action instead of status
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` } }
      );
  
      const updatedLeads = leads.map((lead) =>
        lead._id === leadId
          ? { ...lead, status: response.data.lead.status, operationTeam: response.data.lead.operationTeam }
          : lead
      );
      setLeads(updatedLeads);
  
      alert('Operation team assigned automatically');
    } catch (err) {
      console.error('Error updating lead status:', err);
    }
  };
  
  return (
    <Container>
      <Typography variant="h4" gutterBottom align="center">
        Lead Pipeline
      </Typography>

      {/* Stage Container */}
      <DragDropContext onDragEnd={onDragEnd}>
        <StageContainer>
          {stages.map((stage) => (
            <Droppable droppableId={stage} key={stage}>
              {(provided) => (
                <LeadStage ref={provided.innerRef} {...provided.droppableProps}>
                  <Typography variant="h6" gutterBottom align="center">
                    {stage} ({groupedLeads[stage].length})
                  </Typography>

                  {groupedLeads[stage].map((lead, index) => {
                    console.log(lead._id);
                    
                    return(
  <Draggable key={lead._id} draggableId={lead._id} index={index}>
    {(provided) => (
      <LeadCard ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
        <CardContentStyled>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            {lead.Kid}
          </Typography>
          <Typography variant="body1" fontWeight="bold" gutterBottom>
            {lead.Parent}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            grade: {lead.grade}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            contact: {lead.Phone}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Close Date: {lead.closeDate}
          </Typography>
        </CardContentStyled>

        {/* Action Buttons */}
        {stage === 'New' && (
          <ActionButton 
            key={`contacted-${lead._id}`}  // Ensuring unique key
            variant="contained" 
            color="primary" 
            fullWidth 
            onClick={() => updateLeadStatus(lead._id, 'Contacted')}
          >
            Contact Lead
          </ActionButton>
        )}

        {stage === 'Contacted' && (
          <ActionButton 
            key={`negotiating-${lead._id}`}  // Ensuring unique key
            variant="contained" 
            color="secondary" 
            fullWidth 
            onClick={() => updateLeadStatus(lead._id, 'Negotiating')}
          >
            Start Negotiation
          </ActionButton>
        )}

        {stage === 'Negotiating' && (
          <>
            <ActionButton 
              key={`success-${lead._id}`}  // Ensuring unique key
              variant="contained" 
              color="success" 
              fullWidth 
              onClick={() => updateLeadStatus(lead._id, 'Operations')}
            >
              Negotiation Success (Move to Operations)
            </ActionButton>
            <ActionButton 
              key={`failed-${lead._id}`}  // Ensuring unique key
              variant="contained" 
              color="error" 
              fullWidth 
              onClick={() => updateLeadStatus(lead._id, 'Closed')}
            >
              Negotiation Failed (Move to Closed)
            </ActionButton>
          </>
        )}

        {stage === 'Closed' && lead.retryCount < 3 && (
          <ActionButton 
            key={`retry-${lead._id}`}  // Ensuring unique key
            variant="contained" 
            color="warning" 
            fullWidth 
            onClick={() => updateLeadStatus(lead._id, 'Closed', lead.retryCount + 1)}
          >
            Retry ({3 - lead.retryCount} Attempts Left)
          </ActionButton>
        )}
      </LeadCard>
    )}
  </Draggable>
)})}


                  {provided.placeholder}
                </LeadStage>
              )}
            </Droppable>
          ))}
        </StageContainer>
      </DragDropContext>
    </Container>
  );
};

export default LeadPipelinePage;
