// src/components/OperationsMemberProfile.js

import React from 'react';
import { Box, Typography, Card, CardContent, Divider, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { styled } from '@mui/system';

const CardContainer = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  padding: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: '#333',
  marginBottom: theme.spacing(2),
}));

const OperationsMemberProfile = ({ operationsTeam }) => {
  const { id } = useParams(); // Retrieve the member id from the URL
  const navigate = useNavigate();

  // Find the member by ID
  const member = operationsTeam.find((member) => member.id === parseInt(id));

  if (!member) {
    return <Typography variant="h6">Member not found</Typography>; // Handle undefined member
  }

  const { name, tasksCompleted, dailyTasks } = member;

  return (
    <Box padding={4}>
      <Button variant="outlined" onClick={() => navigate("/dashboard")}>Back to Dashboard</Button>
      
      <CardContainer>
        <CardContent>
          <Title variant="h5">{name}'s Profile</Title>
          <Typography variant="h6" color="primary" gutterBottom>Tasks Completed: {tasksCompleted}</Typography>

          <Divider sx={{ marginBottom: 3 }} />
          
          <Typography variant="body1" color="textSecondary">Daily Tasks</Typography>
          <div style={{ height: '200px', marginTop: '20px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={dailyTasks}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="tasks" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </CardContent>
      </CardContainer>
    </Box>
  );
};

export default OperationsMemberProfile;
